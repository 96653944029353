<template>
    <div>
        <mf-select
            class="update-modal__select"
            :options="displayedOptions"
            @input="handleSelect"
            hide-selected
            v-model="selectValue"
            label="name"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            :placeholder="placeholder"
        >
            <div slot="caret">
                <mf-icon class="multiselect__select" icon-name="icon-down" />
            </div>
        </mf-select>
        <create-quay-modal
            :is-open="isCreateQuayModalShown"
            @close="isCreateQuayModalShown = !isCreateQuayModalShown"
        />
    </div>
</template>

<script>
    import CreateQuayModal from '@/modals/CreateQuayModal';

    export default {
        name: 'QuaySelect',
        components: {
            CreateQuayModal,
        },
        data() {
            return {
                isCreateQuayModalShown: false,
            };
        },
        props: {
            placeholder: {
                type: String,
                default: '',
            },
            value: {
                type: Object,
                default: () => {},
            },

            options: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            selectValue: {
                get() {
                    return this.value;
                },
                set(newValue) {
                    return newValue;
                },
            },
            displayedOptions() {
                return [{ name: 'Add a new Quay', id: 'New_Quay' }, ...this.options];
            },
        },
        methods: {
            handleSelect(e) {
                if (e.id === 'New_Quay') {
                    this.isCreateQuayModalShown = !this.isCreateQuayModalShown;
                    console.log(this.isCreateQuayModalShown);
                } else {
                    this.$emit('input', e);
                }
            },
        },
    };
</script>
