<template>
    <mf-modal small :scrollable="false" v-if="isOpen" @close="$emit('close')">
        <div slot="header">
            Create a new quay
        </div>
        <div class="input__wrapper">
            <mf-select
                class="input unlocode"
                placeholder="Search UNLOCODE"
                :options="displayedUnlocodes"
                v-model="unlocode"
            >
                <div slot="caret">
                    <mf-icon class="multiselect__select" icon-name="icon-down" />
                </div>
            </mf-select>
            <mf-input class="input" placeholder="Quay name" v-model="name" />
            <mf-input class="input" placeholder="Quay short name" v-model="shortName" />
            <mf-input class="input aliase" placeholder="Aliase" v-model="aliase" />
            <mf-button color="blue" @click="addAliase" :disabled="!aliase">Add Aliase</mf-button>
            <div v-if="aliases.length" class="modal__label">
                <span>Aliases list: </span>
                <span
                    class="list-item"
                    v-for="(aliase, index) in aliases"
                    :key="aliase + index"
                    @click="removeFromArray(index, 'aliases')"
                >
                    {{ aliase }}
                    <mf-icon icon-name="icon-close" class="icon-close" />
                </span>
            </div>
            <h3 class="label-title">Possition section</h3>
            <mf-input
                class="input locationType"
                placeholder="Location Type"
                v-model="locationType"
                @input="handleInputValue"
            />
            <div class="possition__wrapper">
                <p>Latitude:</p>
                <mf-input placeholder="Latitude" type="number" v-model.number="position.latitude" />
            </div>
            <div class="possition__wrapper">
                <p>Longitude:</p>
                <mf-input placeholder="Longitude" type="number" v-model.number="position.longitude" />
            </div>
            <h3 class="label-title">Coordinates section</h3>
            <div class="coordinates__wrapper">
                <p>Latitude:</p>
                <mf-input placeholder="Latitude" type="number" v-model.number="area.latitude" />
            </div>
            <div class="coordinates__wrapper">
                <p>Longitude:</p>
                <mf-input placeholder="Longitude" type="number" v-model.number="area.longitude" />
            </div>
            <mf-button color="blue" @click="addCoordinates" :disabled="!area.longitude && !area.latitude">
                Add Coordinates
            </mf-button>
            <div v-if="coordinates.length" class="modal__label">
                <p>Coordinates list:</p>
                <p
                    v-for="(coordinate, index) in coordinates"
                    :key="index"
                    class="coordinate-list"
                    @click="removeFromArray(index, 'coordinates')"
                >
                    <span>Latitude: {{ coordinate.latitude }}, </span>
                    <span>Longitude: {{ coordinate.longitude }} </span>
                    <mf-icon icon-name="icon-close" class="icon-close" />
                </p>
            </div>
        </div>
        <div slot="footer">
            <div class="modal__buttons">
                <mf-button color="green" @click="addQuay" :disabled="isValid">Add</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import { getQuayUnlocode } from '@/helpers/getQuayUnlocode.helper';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CreateQuayModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                unlocode: null,
                name: '',
                shortName: '',
                coordinates: [],
                aliase: '',
                aliases: [],
                position: {
                    latitude: 0,
                    longitude: 0,
                },
                area: {
                    latitude: 0,
                    longitude: 0,
                },
                locationType: '',
            };
        },
        computed: {
            ...mapGetters(['quays', 'userInfo']),
            displayedUnlocodes() {
                return Array.from(new Set(this.quays.map(getQuayUnlocode)));
            },
            isValid() {
                return !this.unlocode || !this.name || !this.shortName || !this.locationType;
            },
        },
        methods: {
            handleInputValue(value) {
                this.locationType = value.toUpperCase().replace(/\s+/g, '_');
            },
            removeFromArray(itemIndex, arrayName) {
                this[arrayName] = this[arrayName].filter((item, index) => index !== itemIndex);
            },
            addAliase() {
                this.aliases.push(this.aliase);
                this.aliase = '';
            },
            addCoordinates() {
                this.coordinates.push(this.area);
                this.area = {
                    latitude: 0,
                    longitude: 0,
                };
            },
            async addQuay() {
                const data = {
                    urn: `urn:mrn:stm:location:${this.unlocode.toLowerCase()}:${this.name
                        .toLowerCase()
                        .replace(/\s+/g, '_')}`,
                    name: this.name,
                    short_name: this.shortName.toUpperCase(),
                    aliases: this.aliases,
                    area: {
                        coordinates: this.coordinates,
                    },
                    location_type: this.locationType,
                    position: this.position,
                    created_by: this.userInfo.email,
                };

                try {
                    await this.$store.dispatch('storeQuay', data);
                    Object.assign(this.$data, this.$options.data());
                    this.$emit('close');
                } catch (error) {
                    for (const value of Object.entries(error.response.data.errors)) {
                        this.$toasted.error(`${value[1]}`);
                    }
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }

    .input__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-end;

        .modal__label {
            width: 100%;
            margin-top: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .list-item {
                margin: 5px;
                display: flex;
                align-items: center;
            }

            .icon-close {
                color: red;
                cursor: pointer;
                width: 10px;
                margin-left: 5px;
            }
        }

        .label-title {
            width: 100%;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            margin-top: 20px;
        }

        .input {
            width: 49%;
            margin-bottom: 15px;
        }

        .input.unlocode {
            width: 100%;
        }

        .input.aliase {
            width: 75%;
            margin-bottom: 0;

            @media (max-width: 545px) {
                width: 60%;
            }
        }

        .possition__wrapper {
            width: 15%;
            color: #fff;
            p {
                margin-bottom: 10px;
            }
        }

        .coordinates__wrapper {
            margin-top: 20px;
            color: #fff;
            width: 33%;
            p {
                margin-bottom: 10px;
            }
        }

        .input.locationType {
            width: 65%;
            margin-bottom: 0;
        }

        .coordinate-list {
            width: 100%;
            margin: 5px 0;
        }
    }
</style>
